<template>
  <div class="custom-height mt-5 pt-5 mb-5">
    <div class="d-flex flex-column align-items-center">
      <div class="card">
        <div class="card-body">
          <div class="d-block m-auto">
            <svg
              width="65"
              height="64"
              viewBox="0 0 65 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M45.6002 26.2202L45.6003 26.2202L45.6061 26.2142C46.5636 25.2237 46.572 23.6583 45.6002 22.6864C44.6316 21.7178 43.035 21.7178 42.0664 22.6864L28.7133 36.0395L22.9335 30.2598C21.9649 29.2912 20.3684 29.2912 19.3998 30.2598C18.4312 31.2284 18.4312 32.8249 19.3998 33.7935L26.9464 41.3402C27.4125 41.8062 28.0459 42.0733 28.7133 42.0733C29.3808 42.0733 30.0142 41.8062 30.4802 41.3402L45.6002 26.2202ZM6.33331 32C6.33331 17.5828 18.0828 5.83331 32.5 5.83331C46.9172 5.83331 58.6666 17.5828 58.6666 32C58.6666 46.4172 46.9172 58.1666 32.5 58.1666C18.0828 58.1666 6.33331 46.4172 6.33331 32Z"
                fill="#509920"
                stroke="#509920"
              />
            </svg>
          </div>
          <div class="heading-08 text-success-07 text-center mt-3">
            Comanda a fost plasată cu succes!
          </div>
          <div class="heading-09 text-neutral-08 mt-3 text-center">
            <div>
              Numărul comenzii: <b>#{{ orderId }}</b>
            </div>
            <div>
              Data ridicării: <b>{{ pickUpDate }}</b>
            </div>
          </div>
        </div>
      </div>
      <div
        class="sub-heading-03 text-neutral-06 text-center mt-5 pe-3 ps-3 p-sm-0"
        style="max-width: 702px"
      >
        Acum că ai descoperit produsele noastre de calitate, îți recomandăm să explorezi și alte
        oferte irezistibile. Continuă cumpărăturile pentru a descoperi surprize și reduceri
        exclusive.
      </div>
      <div class="row mt-5 mt-sm-4 justify-content-between">
        <div class="col-auto">
          <router-link
            :to="{ name: 'HomePage' }"
            class="button btn-medium-wider btn-primary-outlined fw-semibold"
          >
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.97502 5.44165L2.91669 10.5L7.97502 15.5583"
                stroke="#D6366C"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.0834 10.5H3.05835"
                stroke="#D6366C"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            Înapoi acasă
          </router-link>
        </div>
        <div class="col-auto">
          <router-link
            :to="{ name: 'Products' }"
            class="button btn-medium-wider btn-primary-solid h-100 pe-sm-5 ps-sm-5"
          >
            Produse <img src="../../assets/images/icons/arrow-right-20x20.svg" />
          </router-link>
        </div>
      </div>
    </div>
    <div class="bg-img"></div>
  </div>
</template>

<script>
export default {
  name: 'ThanksForOrderView',
  props: {
    pickUpDate: String,
    orderId: String,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.bg-img {
  position: absolute;
  bottom: 101px;
  z-index: -1;
  background-image: url('../../assets/images/bg-img-thanks-for-order.webp');
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  height: 400px;
  width: 100%;
}

.btn-medium-wider {
  padding: 8px 24px;
}
@media (min-width: 576px) {
  .btn-medium-wider {
    padding: 12px 32px;
  }
}

.card-body {
  padding: 64px 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card {
  box-shadow: 10px 10px 40px -10px #00000040;
  border-radius: 12px;
  border: 0px;
  max-width: 427px;
  width: 100%;
}
@media (min-width: 576px) {
  .custom-height {
    height: 77vh;
  }
}
</style>
